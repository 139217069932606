/*
* Copyright 2018.  All Rights Reserved
* Therapy Management Corporation, Inc.
* 
*   DevExtreme style overrides
*/

@import './tms-variables';

/* Color variables */
$color-dx-default-blue-background: #337ab7;
$color-dx-default-blue-border: #2d6da3;
$color-dx-default-data-grid-border: #ddd;

/* General */
.dx-widget {
    font-family: $tms-font-main;
    color: $tms-font-color-default;
    font-size: $tms-body-font-size;
  }

  .dx-field-item-label-text {
    color: $tms-font-color-default;
  }

  .dx-field-item-label-content {
      min-width: 200px;
      white-space: normal;
  }

/* DevEx Scrollbar */
.dx-scrollable-scrollbar.dx-scrollbar-hoverable.dx-scrollbar-vertical,  
.dx-scrollable-scrollbar.dx-scrollbar-hoverable.dx-scrollbar-vertical .dx-scrollable-scroll {  
  width: 12px;
  padding: 0px;
  border-radius: 5px; 
}

.dx-scrollbar-vertical.dx-scrollbar-hoverable .dx-scrollable-scroll .dx-scrollable-scroll-content {
    background-color: $tms-primary-color;
}

  /* Toast styling */
.dx-toast-custom {
    background-color: $color-dx-default-blue-background;
}

.dx-toast-custom-icon {
    font-size: 1.4rem; 
}

/* Horizontal list styling */
.horizontal-list .dx-list-item {
    float: left;
    width: 150px;
    border-top: none;
}

.horizontal-list-md .dx-list-item {
    float: left;
    width: 120px;
    border-top: none;
}

/* Facility home dashboard styling */
.fhd-disciplines-list .dx-list-item {
    float: left;
    width: 80px;
    border-top: none;
}

/* Dx Button styling */
.dx-button {
    background-color: $tms-secondary-color;
    color: $tms-font-color-default;
}

.dx-button-default {
    background-color: $tms-primary-color;
    color: #ffffff;
    border-radius: 5px !important;
    font-weight: 600;
}

.dx-state-disabled.dx-button {
    background-color: $tms-button-disabled-background-color;
    border-color: $tms-button-disabled-background-color;
    color: $tms-button-disabled-font-color;
}

/* Master-Detail grid styling */
.dx-datagrid-rowsview .dx-row > .dx-master-detail-cell {
    padding: 0px;
}

i.dx-icon.dx-icon-edit-button-addrow {
    font-size: 10px !important;
    width: 20px !important;
    height: 20px !important;
}

.dx-datagrid-addrow-button .dx-button-content  {
    padding: 2px 0px 0px 0px !important;
}

/* Tab styling */
.dx-tabs .dx-tabs-wrapper {
    display: flex;
}

.dx-tabs {
    border-top: none;
    border-left: none;
    border-right: none;
    margin-bottom: $tms-medium-padding;
}

.dx-tab {
    background-color: #ffffff !important;
}

.dx-tab-selected {
    border-left: 1px solid #ffffff;
    border-right: 1px solid #ffffff;
    border-bottom: 3px solid $color-dx-default-blue-border;
    color: $tms-primary-color !important;
}

.dx-tab-selected:after {
    border-left: 1px solid #ffffff !important;
    border-right: 1px solid #ffffff !important;
    border-top: none !important;
}

.dx-tabpanel.dx-state-focused .dx-tab:not(.dx-tab-selected):before {
    border-bottom: 1px solid $color-dx-default-data-grid-border !important;
}

.dx-tabpanel.dx-state-focused .dx-multiview-wrapper {
    border: 1px solid $color-dx-default-data-grid-border !important;
}

/* Datagrid Styling */
.dx-header-row > td > .dx-datagrid-text-content {
    font-family: "Roboto", sans-serif;
    color: $tms-font-color-default;
    text-transform: uppercase;
    font-size: .75rem;
}

.dx-row.dx-data-row.dx-row-lines.dx-state-hover td {
    cursor: pointer;
}

.dx-datagrid-rowsview {
    border-top: 2px solid $tms-font-color-default;
}

/* Form Elements Styling */
.dx-checkbox-icon {
    border: 1px solid $tms-primary-color;
}

.dx-checkbox-checked .dx-checkbox-icon {
    background-color: $tms-primary-color;
}

.dx-checkbox-checked .dx-checkbox-icon:before {
    color: $tms-secondary-color;
    background-color: $tms-primary-color;
}

.dx-checkbox.dx-state-readonly .dx-checkbox-icon {
    border-color: $tms-primary-color;
}

.dx-checkbox.dx-state-disabled .dx-checkbox-icon {
    border-color: $tms-light-grey-color;
}

/* Dx popup */
.dx-popup-title .dx-closebutton {
    background-color: transparent;
}

.dx-popup-title .dx-closebutton .dx-icon {
    font-weight: bold;
}

// dropdown button 
.dx-dropdownbutton-action.dx-button {
    .dx-button-content {
        // padding-right: 4px;
        // padding-left: 10px;
        background-color: #ffffff;
        color: #0071BC;
        font-size: 11px;
        font-weight: 600;
    }

    .dx-icon {
        color: #0071BC;
    }
}

// dx uploader
.dx-fileuploader-upload-button {
    display: none;
}

/* List in Drop Down max height */

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    .drop-down-list-height {
        height: 200px;
    }
}

@media only screen and (min-width: 768px) {
    .drop-down-list-height {
        height: 200px;
    }
}

@media only screen and (min-width: 992px) {
    .drop-down-list-height {
        height: 500px;
    }
}