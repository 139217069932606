/*
* Copyright 2022.  All Rights Reserved
* Therapy Management Corporation, Inc.
* 
*   Angular material style overrides
*/

@import './tms-variables';


.mat-select-arrow {
    color: #333
}

.mat-form-field-wrapper {
    padding-bottom: 0;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 0.5em 0;
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick {
    color: rgba(0, 0, 0, 0.12);
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
    color: $tms-primary-color;
}

.mat-dialog-title {
    font-family: $tms-font-main;
}