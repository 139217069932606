$tms-font-main: 'Open Sans', sans-serif;

$tms-body-font-size: 13px;

// Padding - based on 16px font
$tms-xsmall-padding: .5rem;
$tms-small-padding: .625rem;
$tms-medium-padding: 1.25rem;
$tms-large-padding: 2.5rem;

$tms-h1-font-size: 24px;
$tms-h2-font-size: 20px;
$tms-h3-font-size: 18px;
$tms-h4-font-size: 16px;
$tms-h5-font-size: 14px;

$tms-row-padding: 1rem;

// Colors
$tms-primary-color: #0071BC;
$tms-primay-color-hover: #005b97;
$tms-secondary-color: #EAEDF0;
$tms-font-color-default: #44566C;
$tms-active-color: #F2B045;
$tms-active-hover-color: #E19D30;
$tms-title-color: #161E27;
$tms-warning-color: #FF0E00;
$tms-success-color: #3BB54C;
$tms-caution-color: #F2B045;
$tms-gray-header-color: #F3F5F8;
$tms-light-grey-color: #D9DBDE;
$tms-medium-grey-color: #8697A8;
$tms-background-color: #EDF1F7;

$tms-anchor-link-color: $tms-primary-color;
$tms-edit-button-background-color: #8697A8;
$tms-primary-button-background-color:  $tms-active-color;
$tms-primary-button-background-hover-color: $tms-active-hover-color;
$tms-primary-button-font-color: #161E27;

$tms-secondary-button-background-hover-color: #F5F5F5;

$tms-button-disabled-background-color: #D7D7D7;
$tms-button-disabled-font-color: #ffffff;

$tms-menu-logo-background-color: #073271;
$tms-menu-background: #003A60;

$tms-menu-item-color: #D9DBDE;
$tms-menu-selected-header-item-color: $tms-active-color;
$tms-menu-selected-header-background: rgba(25,28,40,0.1);

$tms-menu-selected-item-background: $tms-active-color;
$tms-menu-seleted-item-color: #000000;

$tms-menu-icon: #D9DBDE;
$tms-menu-active-icon: $tms-active-color;

$tms-card-background-color: #ffffff;

$tms-border-color: #e4e9f2;

// Modal Sizes
$tms-full-screen-modal-width: 90vw;
$tms-full-screen-modal-height: 90vh;
$tms-modal-screen-max-height: 70vh;