// themes - our custom or/and out of the box themes
@import 'tms.nb.scss';

// framework component themes (styles tied to theme variables)
@import '@nebular/theme/styles/globals';

// global app font size
@import './font-size';

// install the framework and custom global styles
@include nb-install() {

  // framework global styles
  @include nb-theme-global();
};

$roboto-font-path: '~roboto-fontface/fonts';
@import 'roboto-fontface/css/roboto/sass/roboto-fontface-medium.scss';

html, body { height: 100%; }
body { margin: 0; }
