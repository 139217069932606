/*
* Copyright 2018.  All Rights Reserved
* Therapy Management Corporation, Inc.
* 
*   Application wide styling 
*/

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$tms-admin-primary: mat.define-palette(mat.$indigo-palette);
$tms-admin-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$tms-admin-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$tms-admin-theme: mat.define-light-theme((
  color: (
    primary: $tms-admin-primary,
    accent: $tms-admin-accent,
    warn: $tms-admin-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($tms-admin-theme);

@import './tms-variables';
@import './tms.am.scss';

// Header
.user-profile-name {
    font-weight: 600;
    text-align: right;
    font-size: 1rem;
}

.header-icons {
    color: $tms-primary-color !important;
    background-color: #ffffff !important;
}

// Modals
.modal-xl .modal-dialog {
    min-width: 70vw;
    min-height: 70vh;
  }

.modal-full-screen .modal-dialog {
    min-width: $tms-full-screen-modal-width;
}

.modal-z-index {
    z-index: 3000;
}

.tms-modal-full-screen-height {
    min-height: $tms-modal-screen-max-height;
}

.mat-dialog {
    height: auto;
}

//.cdk-overlay-container {
    // z-index: 1500 !important;
//}

.float-right {
    text-align: right;
}

// Page Heading
.page-heading {
    line-height: 33px;
    margin-bottom: $tms-xsmall-padding;
    margin-left: $tms-small-padding;
}

.page-heading-font {
    color: $tms-title-color;
    //height: 33px;
    font-size: $tms-h1-font-size;
    font-weight: bold;
}

.page-heading-title {
    color: $tms-title-color;
    font-size: $tms-h1-font-size;
    font-weight: bold;
    padding-right: $tms-small-padding;
    margin-left: $tms-medium-padding;
}

.page-heading-right-div {
    float: right;
    margin-right: $tms-medium-padding !important;
}

// TMS/NB Card
.tms-card {
    margin-right: $tms-medium-padding !important;
    margin-left: $tms-xsmall-padding !important;
    margin-bottom: $tms-medium-padding !important;
}

.tms-card-body {
    padding-right: $tms-small-padding !important;
    padding-left: $tms-small-padding !important;
    padding-bottom: $tms-medium-padding !important;
}

.tms-page-card {
    min-height: 70vh;
}

// Sections
.tms-section-divider {
    margin-top: $tms-large-padding;
}

.tms-card-body-row {
    margin-left: 0px;
    margin-right: 0px;
}

// Margin & Padding
.tms-small-margin-left {
    margin-left: $tms-small-padding;
}

// Rows
.tms-row-divider {
    margin-top: $tms-row-padding;
}

// Buttons
.tms-primary-button {
    background-color: $tms-primary-button-background-color;
    border-color: $tms-primary-button-background-color;
    color: $tms-primary-button-font-color;
    font-weight: 600;
}

.tms-primary-button:hover {
    background-color: $tms-primary-button-background-hover-color !important;
    color: $tms-primary-button-font-color !important;
}

.tms-primary-button.dx-button-default.dx-state-hover {
    background-color: $tms-primary-button-background-hover-color !important;
    color: $tms-primary-button-font-color !important;
}

.tms-primary-button.dx-button.dx-state-disabled {
    background-color: $tms-button-disabled-background-color !important;
    border-color: $tms-button-disabled-background-color !important;
    color: $tms-button-disabled-font-color !important;
}

.tms-primary-button:focus {
    background-color: $tms-primary-button-background-color !important;
    color: $tms-primary-button-font-color !important;
}

.tms-secondary-button {
    background-color: #ffffff;
    color: $tms-primary-color !important;
    border-color: $tms-primary-color;
    font-weight: 600;
}

.tms-secondary-button.dx-button-default.dx-state-hover,
.tms-secondary-button:hover,
.tms-secondary-button:focus {
    background-color: $tms-secondary-button-background-hover-color !important;
    border-color: $tms-primary-color;
    color: $tms-primary-color;
}

.tms-secondary-button.dx-button.dx-state-disabled {
    background-color: #ffffff !important;
    opacity: .5;
}

.tms-blue-theme-button {
    background-color: $tms-primary-color;
    border-color: $tms-primary-color;
    color: $tms-secondary-color;
}

.tms-blue-theme-button.dx-button-has-icon {
    .dx-button-content {
        .dx-icon {
            color: $tms-secondary-color;
        }
    }
}

.tms-blue-theme-button:hover,
.tms-blue-theme-button:focus {
    background-color: $tms-primay-color-hover;
    color: $tms-secondary-color;
}

// Tertiary button is a normal dx-button

.tms-submit-group {
    padding-top: $tms-large-padding;
    float: right;
    padding-bottom: 1rem;
}

.tms-submit-button {
    margin-left: $tms-small-padding;
}

.tms-submit-buttons-container {
    padding: 10px 0px;
    height: 50px;
    box-shadow: -1px -3px 100px -15px #888888;
}

.tms-icon-button {
    background-color: #ffffff;
    border: none;
}

.tms-icon-button.dx-button-has-icon  .dx-button-content {
    padding: 2px;
}

.tms-icon-button.dx-button-has-icon  .dx-button-content .dx-icon {
    background-color: #ffffff;
    border: none;
}

.tms-icon-button:focus {
    background-color: #ffffff;
    border: none;
}

.tms-edit-icon-button {
    background-color: #ffffff;
    border: none;
}

.tms-edit-icon-button.dx-button-has-icon  .dx-button-content {
    padding: 2px;
}

.tms-edit-icon-button.dx-button-has-icon  .dx-button-content .dx-icon {
    color: $tms-primary-color;
}

.tms-secondary-button.dx-button-has-icon .dx-button-content .dx-icon {
    color: $tms-primary-color;
}

.tms-delete-icon-button {
    background-color: #ffffff;
    border: none;
}

.tms-delete-icon-button.dx-button-has-icon  .dx-button-content {
    padding: 2px;
}

.tms-delete-icon-button.dx-button-has-icon  .dx-button-content .dx-icon {
    color: $tms-warning-color;
}

.tms-back-icon-button {
    background-color: #ffffff;
}

.tms-back-icon-button {
    color: $tms-primary-color;
}

.tms-back-icon-button.dx-button-has-icon  .dx-button-content .dx-icon {
    color: $tms-primary-color;
}


.tms-edit-ellipses-button {
    background-color: #ffffff;
    border: none;
}

.tms-edit-ellipses-button.dx-button-has-icon  .dx-button-content {
    padding: 2px;
}

.tms-edit-ellipses-button.dx-button-has-icon  .dx-button-content .dx-icon {
    color: $tms-font-color-default;
}

.tms-edit-button {
    color: $tms-primary-color;
    background-color: #ffffff;
    border-color: $tms-primary-color; 
}

.tms-delete-button {
    color: $tms-warning-color;
    background-color: #ffffff;
    border-color: $tms-warning-color;
}

.tms-cursor-pointer {
    cursor: pointer;
}

// Grids
.tms-matrix-grid {
    display: grid;
    grid-template-columns: 30px auto auto auto auto auto auto auto;
    font-size: 15px;
    padding: 10px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    grid-row-gap: 0px;
}

.tms-matrix-grid .header-item {
    border-bottom: 2px solid $tms-font-color-default;
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    text-transform: uppercase;
}

.tms-matrix-grid .data-item {
    border-bottom: 1px solid $tms-font-color-default;
    padding: 5px;
    text-align: center;
}

.tms-matrix-grid .vertical-header {
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
}

// Text
.disable-text {
    opacity: 0.4;
}

.required-mark {
    color: #ea4444;
}

.warning-alert {
    color: $tms-warning-color;
    font-weight: bold;
}

h1 {
    font-size: $tms-h1-font-size !important;
    font-weight: bold !important;
}

h2 {
    font-size: $tms-h2-font-size !important;
    font-weight: bold !important;
}

h3 {
    font-size: $tms-h3-font-size !important;
    font-weight: bold !important;
}

h4 {
    font-size: $tms-h4-font-size !important;
    font-weight: bold !important;
}

h5 {
    font-size: $tms-h5-font-size !important;
    font-weight: bold !important;
}

// Icon Colors
.tms-success {
    color: $tms-success-color;
}

.tms-warning {
    color: $tms-warning-color;
}

.tms-caution {
    color: $tms-caution-color;
}

// Solid Grey Line
.solid-grey-line {
	box-sizing: border-box;
	height: 1px;
    border: 0.5px solid #D9DBDE;
    margin-top: $tms-small-padding;
}

hr {
    margin-top: $tms-xsmall-padding;
    margin-bottom: $tms-xsmall-padding;
}

// Field label and value
.tms-field {
    display: flex;
    min-height: auto; // 36px;
    padding: 0;
}

.tms-field-label {
    margin: auto;
    padding-right: 10px;
    text-overflow: ellipsis;
}

.tms-field-value {
    display: inline-block;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
}

// angular material dialog
.tms-ng-mat-dialog {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.tms-ng-mat-dialog-spacer {
    flex-grow: 1;
}

.tms-ng-mat-dialog-actions {
    margin-top: 20px;
}

mat-dialog-content {
    max-height: 100%;
}

// Default scroll bar
/* width */
::-webkit-scrollbar {
    width: 15px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: $tms-secondary-color;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $tms-primary-color;
    border-radius: 3px;
  }


// file uploader
.tms-fileuploader-container {
    border: 1px solid #d3d3d3;
    margin: 20px 20px 0 20px;
}

// User Lane
h1.userlane-element-helper.usln-p-assistant-head__title {
    color: #303030;
    font-size: 24px;
    line-height: 32px;
    font-family: "Noto Sans", sans-serif;
}

p.userlane-element-helper.usln-p-assistant-head__subtitle {
    color: #666;
    font-size: 18px;
    line-height: 24px;
    font-family: "Noto Sans", sans-serif;
}

// Scroll to top button
.tms-to-top-button {
    float: right;
    position: fixed;
    bottom: 0;
    right: 20px;
}