/*
* Copyright 2018.  All Rights Reserved
* Therapy Management Corporation, Inc.
* 
*   Styling for nebular components 
*/

 // @nebular theming framework
@import '@nebular/theme/styles/theming';
// @nebular out of the box theme. Registers default theme 
@import '@nebular/theme/styles/themes';

@import './tms-variables';

 $theme: (
  font-family-primary: $tms-font-main,
  font-family-secondary: font-family-primary,

  text-h6-font-weight: 200,
  text-h5-font-weight: 300,
  text-h4-font-weight: 400,
  text-h3-font-weight: 500,
  text-h2-font-weight: 600,
  text-h1-font-weight: 800,

  text-subtitle-font-weight: 500,
  text-subtitle-2-font-weight: 600,

//   // TODO: use it as a default font-size
//   base-font-size: 16px,

//   font-size-xlg: 1.25rem,
//   font-size-lg: 1.125rem,
//   font-size: 1rem,
   text-subtitle-font-size: 0.875rem,
//   font-size-xs: 0.75rem,

border-radius: 0.375rem,
//   line-height: 1.25,

color-bg: #ffffff,
//   color-bg-active: #e9edf2,
color-fg: #a4abb3,
color-fg-heading: #2a2a2a,
//   // color-fg-text: #4b4b4b,
//   color-fg-heading: $tms-font-color-default,
//   color-fg-text: $tms-font-color-default,
//   //color-fg-highlight: #40dc7e,
//   color-fg-highlight: $tms-menu-active-icon,

divider-color: #ebeef2,

//   color-gray: rgba(81, 113, 165, 0.15),
//   color-neutral: transparent,
//   color-white: #ffffff,
//   color-disabled: rgba(255, 255, 255, 0.4),

//   color-primary: #8a7fff,
//   color-success: #40dc7e,
//   color-info: #4ca6ff,
//   color-warning: #ffa100,
//   color-danger: #ff4c6a,

//   // TODO: move to constants
//   social-color-facebook: #3b5998,
//   social-color-twitter: #55acee,
//   social-color-google: #dd4b39,
//   social-color-linkedin: #0177b5,
//   social-color-github: #6b6b6b,
//   social-color-stackoverflow: #2f96e8,
//   social-color-dribble: #f26798,
//   social-color-behance: #0093fa,

//   border-color: color-gray,
//   shadow: 0 2px 12px 0 #dfe3eb,

link-text-color: $tms-anchor-link-color,
link-text-hover-color: $tms-anchor-link-color,

scrollbar-color: $tms-primary-color,
scrollbar-background-color: $tms-secondary-color,
scrollbar-width: 15px,
//   scrollbar-thumb-radius: 2.5px,

//   radial-gradient: none,
//   linear-gradient: none,

//   // card-font-size: font-size,
//   card-font-size: 11pt,
//   card-line-height: line-height,
//   card-font-weight: font-weight-normal,
//   card-fg: color-fg, // TODO: not used
//   card-fg-text: color-fg-text,
//   card-fg-heading: color-fg-heading, // TODO: not used
//   card-bg: color-bg,
//   card-height-xxsmall: 96px,
//   card-height-xsmall: 216px,
//   card-height-small: 336px,
//   card-height-medium: 456px,
//   card-height-large: 576px,
//   card-height-xlarge: 696px,
//   card-height-xxlarge: 816px,
//   card-shadow: shadow,
//   card-border-radius: radius,
//   // card-padding: 10px,
//   // card-margin: 11px,
//   //card-padding: padding,
//   //card-margin: margin,
//   card-header-font-family: font-secondary,
//   // card-header-font-size: font-size-lg,
//   card-header-font-size: 12pt,
//   card-header-font-weight: font-weight-bold,
//   card-separator: separator,
//   card-header-fg: color-fg, // TODO: not used
//   card-header-fg-heading: color-fg-heading,
//   card-header-active-bg: color-fg,
//   card-header-active-fg: color-bg,
//   card-header-disabled-bg: color-disabled,
//   card-header-primary-bg: color-primary,
//   card-header-info-bg: color-info,
//   card-header-success-bg: color-success,
//   card-header-warning-bg: color-warning,
//   card-header-danger-bg: color-danger,

//   header-font-family: font-secondary,
//   header-font-size: font-size,
//   header-line-height: line-height,
//   header-fg: color-fg-heading,
//   header-bg: color-bg,
//   header-height: 4.45rem,
//   header-padding: 0.75rem,
//   header-shadow: shadow,

//   footer-height: 3.425rem,
//   footer-padding: 1.25rem,
//   footer-fg: color-fg-heading,
//   footer-bg: color-bg,
//   footer-separator: separator,
//   footer-shadow: shadow,

//   layout-font-family: font-main,
//   layout-font-size: font-size,
//   layout-line-height: line-height,
//   layout-fg: color-fg,
//   layout-bg:  #f5f5f5, // #ebeff5, // #ffffff, // #ebeff5,
//   layout-min-height: 100vh,
//   layout-content-width: 1400px,
//   layout-window-mode-min-width: 300px,
//   layout-window-mode-max-width: 1920px,
//   layout-window-mode-bg: layout-bg,
//   layout-window-mode-padding-top: 4.75rem,
//   layout-window-shadow: shadow,
//   //layout-padding: 2.25rem 2.25rem 0.75rem,
   layout-padding: $tms-medium-padding $tms-medium-padding $tms-small-padding, 
//   layout-medium-padding: 1.5rem 1.5rem 0.5rem,
//   layout-small-padding: 1rem 1rem 0,

//   sidebar-font-size: font-size,
//   sidebar-line-height: line-height,
//   sidebar-fg: color-fg-heading,
//   sidebar-bg: color-bg,
//   sidebar-height: 100vh,
//   sidebar-width: 15rem,
//   sidebar-width-compact: 3.5rem,
//   sidebar-padding: 0, // padding,
//   sidebar-header-gap: 2rem,
//   sidebar-header-height: initial,
//   sidebar-footer-height: 3.5rem,
//   sidebar-shadow: shadow,

  menu-text-font-family: font-family-secondary,
  menu-text-font-size: text-subtitle-font-size,
  menu-text-font-weight: text-subtitle-font-weight,
  menu-text-color: $tms-menu-item-color,
  menu-background-color: transparent,
  menu-item-active-text-color: $tms-menu-selected-header-item-color,
  menu-item-active-background-color: $tms-menu-selected-header-background,

  menu-submenu-background-color: $tms-menu-selected-header-background,
  menu-submenu-text-color: $tms-menu-item-color,
  menu-submenu-item-active-text-color: $tms-menu-seleted-item-color,
  menu-submenu-item-active-background-color: $tms-menu-selected-item-background,
  menu-submenu-item-active-hover-background-color: $tms-menu-selected-item-background,
  menu-submenu-item-active-hover-text-color: $tms-menu-seleted-item-color,
  menu-submenu-item-active-border-color: $tms-menu-selected-item-background,
  menu-item-hover-text-color: $tms-menu-selected-item-background,
  menu-item-hover-background-color: menu-submenu-background-color,
  menu-submenu-item-border-width: 0,
  menu-submenu-item-border-radius: 0,
  menu-submenu-item-padding: 0.5rem 1rem,
  // menu-submenu-padding: 0.5rem,

  menu-group-text-color: $tms-menu-item-color,
  menu-item-padding: 0.675rem 1rem 0.675rem 0.75rem,
  menu-item-divider-color: transparent,
  menu-item-icon-width: text-subtitle-font-size,
  menu-item-icon-margin: 0 0.5rem 0 0,
  menu-item-icon-color: $tms-menu-icon,
  menu-item-icon-active-color: $tms-menu-active-icon,

//   tabs-font-family: font-secondary,
//   // tabs-font-size: font-size-lg,
//   tabs-font-size: 11pt,
//   tabs-content-font-family: font-main,
//   tabs-content-font-size: font-size,
//   tabs-active-bg: transparent,
//   tabs-active-font-weight: card-header-font-weight,
//   tabs-padding: padding,
//   tabs-content-padding: 0,
//   tabs-header-bg: transparent,
//   tabs-separator: separator,
//   tabs-fg: color-fg,
//   tabs-fg-text: color-fg-text,
//   tabs-fg-heading: color-fg-heading,
//   tabs-bg: transparent,
//   tabs-selected: color-success,

//   route-tabs-font-family: font-secondary,
//   route-tabs-font-size: font-size-lg,
//   route-tabs-active-bg: transparent,
//   route-tabs-active-font-weight: card-header-font-weight,
//   route-tabs-padding: padding,
//   route-tabs-header-bg: transparent,
//   route-tabs-separator: separator,
//   route-tabs-fg: color-fg,
//   route-tabs-fg-heading: color-fg-heading,
//   route-tabs-bg: transparent,
//   route-tabs-selected: color-success,

//   user-font-size: font-size,
//   user-line-height: line-height,
//   user-bg: color-bg,
//   user-fg: color-fg,
//   user-fg-highlight: #bcc3cc,
//   user-font-family-secondary: font-secondary,
//   user-size-small: 1.5rem,
//   user-size-medium: 2.5rem,
//   user-size-large: 3.25rem,
//   user-size-xlarge: 4rem,
//   user-menu-fg: color-fg-heading,
//   user-menu-bg: color-bg,
//   user-menu-active-fg: #ffffff,
//   user-menu-active-bg: color-success,
//   user-menu-border: color-success,

//   actions-font-size: font-size,
//   actions-font-family: font-secondary,
//   actions-line-height: line-height,
//   actions-fg: color-fg,
//   actions-bg: color-bg,
//   actions-separator: none,
//   actions-padding: calc(#{$tms-medium-padding} / 2),
//   actions-size-small: 1.5rem,
//   actions-size-medium: 2.25rem,
//   actions-size-large: 3.5rem,

//   search-btn-open-fg: color-fg,
//   search-btn-close-fg:	color-fg,
//   search-bg: layout-bg,
//   search-bg-secondary: color-fg,
//   search-text: color-fg-heading,
//   search-info: color-fg,
//   search-dash: color-fg,
//   search-placeholder: color-fg,

//   smart-table-header-font-family: font-secondary,
//   smart-table-header-font-size: font-size,
//   smart-table-header-font-weight: font-weight-bold,
//   smart-table-header-line-height: line-height,
//   smart-table-header-fg: color-fg-heading,
//   smart-table-header-bg: color-bg,

//   smart-table-font-family: font-main,
//   smart-table-font-size: font-size,
//   smart-table-font-weight: font-weight-normal,
//   smart-table-line-height: line-height,
//   smart-table-fg: color-fg-heading,
//   smart-table-bg: color-bg,

//   smart-table-bg-even: #f5f7fc,
//   smart-table-fg-secondary: color-fg,
//   smart-table-bg-active: #e6f3ff,
//   smart-table-padding: 0.875rem 1.25rem,
//   smart-table-filter-padding: 0.375rem 0.5rem,
//   smart-table-separator: separator,
//   smart-table-border-radius: radius,

//   smart-table-paging-border-color: separator,
//   smart-table-paging-border-width: 1px,
//   smart-table-paging-fg-active: #ffffff,
//   smart-table-paging-bg-active: color-success,
//   smart-table-paging-hover: rgba(0, 0, 0, 0.05),

//   toaster-bg: color-primary,
//   toaster-fg-default: color-inverse,
//   toaster-btn-close-bg: transparent,
//   toaster-btn-close-fg: toaster-fg-default,
//   toaster-shadow: shadow,

//   toaster-fg: color-white,
//   toaster-success: color-success,
//   toaster-info: color-info,
//   toaster-warning: color-warning,
//   toaster-wait: color-primary,
//   toaster-error: color-danger,

//   btn-fg: color-white,
//   btn-font-family: font-secondary,
//   btn-line-height: line-height,
//   btn-disabled-opacity: 0.3,
//   btn-cursor: default,

//   btn-primary-bg: color-primary,
//   btn-secondary-bg: transparent,
//   btn-info-bg: color-info,
//   btn-success-bg: color-success,
//   btn-warning-bg: color-warning,
//   btn-danger-bg: color-danger,

//   btn-secondary-border: #dadfe6,
//   btn-secondary-border-width: 2px,

//   btn-padding-y-lg: 0.875rem,
//   btn-padding-x-lg: 1.75rem,
//   btn-font-size-lg: font-size-lg,

//   // default size
//   btn-padding-y-md: 0.75rem,
//   btn-padding-x-md: 1.5rem,
//   btn-font-size-md: 1rem,

//   btn-padding-y-sm: 0.625rem,
//   btn-padding-x-sm: 1.5rem,
//   btn-font-size-sm: 0.875rem,

//   btn-padding-y-tn: 0.5rem,
//   btn-padding-x-tn: 1.25rem,
//   btn-font-size-tn: 0.75rem,

//   btn-border-radius: radius,
//   btn-rectangle-border-radius: 0.25rem,
//   btn-semi-round-border-radius: 0.75rem,
//   btn-round-border-radius: 1.5rem,

//   btn-hero-shadow: none,
//   btn-hero-text-shadow: none,
//   btn-hero-bevel-size: 0 0 0 0,
//   btn-hero-glow-size: 0 0 0 0,

//   btn-outline-fg: color-fg-heading,
//   btn-outline-hover-fg: #ffffff,

//   btn-group-bg: layout-bg,
//   btn-group-fg: color-fg-heading,
//   btn-group-separator: #dadfe6,

//   form-control-text-primary-color: color-fg-heading,
//   form-control-text-secondary-color: color-fg,
//   form-control-font-family: font-secondary,
//   form-control-bg: color-bg,
//   form-control-focus-bg: color-bg,

// //  form-control-border-width: 2px,
//   form-control-border-width: 1px,
//   form-control-border-type: solid,
//   form-control-border-radius: radius,
//   form-control-border-color: #dadfe6,
//   form-control-selected-border-color: color-success,

//   form-control-info-border-color: color-info,
//   form-control-success-border-color: color-success,
//   form-control-danger-border-color: color-danger,
//   form-control-warning-border-color: color-warning,

//   form-control-placeholder-color: color-fg,
//   form-control-placeholder-font-size: 1rem,

//   form-control-font-size: 1rem,
//   form-control-sm-font-size: font-size-sm,
//   form-control-sm-padding: 0rem,
//   form-control-lg-font-size: font-size-lg,
//   form-control-lg-padding: 0rem,

//   form-control-label-font-weight: 400,

//   form-control-feedback-font-size: 0.875rem,
//   form-control-feedback-font-weight: font-weight-normal,

//   checkbox-bg: transparent,
//   checkbox-size: 1.25rem,
//   checkbox-border-size: 2px,
//   checkbox-border-color: form-control-border-color,
//   checkbox-selected-border-color: color-success,
//   checkbox-fg: color-fg-heading,
//   radio-fg: color-success,

//   modal-font-size: font-size,
//   modal-line-height: line-height,
//   modal-font-weight: font-weight-normal,
//   modal-fg: color-fg-text,
//   modal-fg-heading: color-fg-heading,
//   modal-bg: color-bg,
//   modal-border: transparent,
//   modal-border-radius: radius,
//   modal-padding: padding,
//   modal-header-font-family: font-secondary,
//   modal-header-font-weight: font-weight-bolder,
//   modal-header-font-size: font-size-lg,
//   modal-body-font-family: font-main,
//   modal-body-font-weight: font-weight-normal,
//   modal-body-font-size: font-size,
//   modal-separator: separator,
);

// register the theme
$nb-themes: nb-register-theme($theme, tms, default);

$nb-enabled-themes: (tms);